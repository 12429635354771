.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lottie-wave {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -2;
  overflow: hidden;
  height: 175vw
}

.lottie-wave svg {
  scale: 3.5;
}

.container-title {
  width: 100%;
  margin-top: 65px;
}

.container-title h1 {
  text-align: center;
  font-size: 5.5vw;
}

.light-h1 {
  color: #515151;
  text-shadow: 0.4vw 3px white;
}

.dark-h1 {
  color: white;
  text-shadow: 0.4vw 3px #515151;
}

.design-part-text-h1 {
  color: white;
  text-shadow: 0.4vw 3px #515151
}

.container-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
}

.container-icons a {
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container-icons a:first-child {
  border-radius: 45px;
  width: 29px;
  height: 29px;
}
.container-icons a:nth-child(2) {
  border-radius: 4px;
  width: 28px;
  height: 28px;
}
.container-icons a:last-child {
  border-radius: 5px;
  width: 31px;
  height: 25px;
}

.container-icons a img {
  transform: scale(0.6);
}

.light-icon {
  filter: brightness(4);
}

.container-presentation {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 40px;
}

.text-presentation {
  font-size: large;
  text-align: center;
  margin: 0;
}

.container-logo {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container-logo > img {
  height: fit-content;
}

.light-logo {
  filter: brightness(3);
}

.container-logo > img:first-child {
  border-radius: 20px;
  width: 17%;
}
.container-logo > img:nth-child(2) {
  border-radius: 17px;
  width: 28%;
}
.container-logo > img:last-child {
  width: 43%;
}

/* Big screen */
@media (min-width: 1900px)  {
  .container-title h1 {
    font-size: 95px;
  }  
}

@media (min-width: 550px)  {
  .lottie-wave {
    height: 110vw;
  }
  .lottie-wave svg {
    scale: 2;
  }

  .container-presentation {
    display: block;
  }

  .text-presentation {
    text-align: justify;
  }

  .container-logo {
    float: right;
    margin-left: 10px;
    display: flex;
    justify-content: space-around;
    width: 50%;
    max-width: 400px;
    min-width: 340px;
    margin-top: 20px;
  }

  .container-logo > img:first-child {
    width: 27%;
  }
  .container-logo > img:nth-child(2) {
    width: 52%;
  }
  .container-logo > img:last-child {
    width: 85%;
  }
}

/* Part laptop */
@media (min-width: 800px)  {
  .lottie-wave {
    height: 80vw;
  }

  .lottie-wave svg {
    scale: 1.5;
  }

  .container-title {
    margin-top: 100px;
  }

  .container-icons a img {
    transform: scale(1);
  }

  .container-icons a:first-child {
    width: 49px;
    height: 49px;
  }
  .container-icons a:nth-child(2) {
    width: 46px;
    height: 46px;
  }
  .container-icons a:last-child {
    border-radius: 7px;
    width: 52px;
    height: 42px;
  }

  .text-presentation {
    font-size: x-large;
  }
}