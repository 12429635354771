.header {
    position: absolute;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: 85%;
    max-width: 1300px;
    margin-top: 25px;
}

.dark-mode {
    width: 40.2px;
    background-color: transparent;
    height: 27px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark-mode > input {
    position: absolute;
    width: 39px;
    height: 30px;
    filter: opacity(0);
    cursor: pointer;
    z-index: 3;
}

.input-light {
    box-shadow: -1.5px 1.5px 0 #ffffff;
    border: solid 2px #ffffff;
    background: #515151;
}

.input-dark {
    box-shadow: -1.5px 1.5px 0 #515151;
    border: solid 2px #515151;
    background: #ffffff;
}

/* Part laptop */
@media (min-width: 800px)  {
    .header {
        width: 415px;
        padding: 15px 30px 0px 30px;
        border-radius: 35px;
        position: fixed;
        box-shadow: 0px 1.5px 0px #6e6e6e94;
    }
    .header-light-laptop, .input-light {
        background: #9d4edd;
    }

    .header-dark-laptop {
        background: #10002b;
    }

    .input-dark {
        background: #10002b;
        box-shadow: -1.5px 1.5px 0 #ffffff;
        border: solid 2px #ffffff;
    }

    .input-dark > img {
        filter: brightness(3);
    }
}