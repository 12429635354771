@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Lemon&family=Lora&family=Inter&display=swap');

body {
    margin: 0;
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#root {
    width: 100%;
}

#App {
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    margin: 0 25px;
}

a {
    -webkit-tap-highlight-color: transparent;
}
a:focus {
    outline: none;
}

h1 {
    font-family: 'Lemon', serif;
    font-size: 4.7vw;
    letter-spacing: 3px;
}

/* Settings theme */

.light-text {
    color: #ffffff;
}

.dark-text {
    color: #0e0d0d;
}

.light-background {
    background-color: #9d4edd;
}

.dark-background {
    background-color: #10002b;
}

button {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: medium;
    background-color: transparent;
    border: none;
    line-height: 25px;
    -webkit-tap-highlight-color: transparent;
}

p, label {
    font-family: 'Inter', sans-serif;
    white-space: pre-line;
}

h2 {
    font-family: 'Inter', sans-serif;
    font-size: 4.5vw;
    padding: 5px;
    letter-spacing: 5px;
    border-radius: 2px;
    width: fit-content;
}

.light-h2 {
    color: #ffffff;
    border-right: double 5px #ffffff;
    border-bottom: double 5px #ffffff;
}

.dark-h2 {
    color: #9d4edd;
    border-right: double 5px #A054DE;
    border-bottom: double 5px #A054DE;
}

.no-wrap-fullstack {
    white-space: nowrap;
}

h3 {
    font-family: 'Lemon', serif;
    letter-spacing: 1px;
    margin-top: 25px;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 2.5px;
    color: white;
    text-decoration-color: white;
}

label {
    font-weight: bold;
}

/* Part tablet */
@media (min-width: 500px)  {
    h1 {
        font-size: x-large;
    }
}

/* Part laptop */
@media (min-width: 800px)  {
    #App {
        margin: 0 70px;
    }

    h2 {
        font-size: 40px;
    }
}

@media (min-width: 1600px)  {
    #root {
        display: flex;
        justify-content: center;
    }
}