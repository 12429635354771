.container_contact {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}

.container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-60px);
}

.container-mini-curt {
    border: solid 5px #9d4edd;
    box-shadow: 0px -8px 58px #c3c3c3;
    width: 180px; 
    border-radius: 120px;
    background: white;
    overflow: hidden;
    transform: translateY(93px)
}

.mini-curt {
    width: 204px;
    scale: 1.6;
    transform: translateY(130px);
    position: relative;
    z-index: -3;
}

.form {
    display: flex;
    flex-direction: column;
    background: burlywood;
    padding: 125px 45px 25px;
    border-radius: 40px;
}

.form > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.container-input {
    display: flex;
    flex-direction: column; 
    width: 100%
}

.form label {
    margin-bottom: 10px;
    font-size: large;
    color: #833e86;
}

.form input {
    margin-bottom: 30px;
    border-radius: 25px;
    height: 30px;
    padding: 0 15px;
}

.form input:placeholder-shown {
    text-overflow: ellipsis;
}

.form textarea {
    border-radius: 20px;
    height: 120px;
    outline: none;
    padding: 15px 15px;
    resize: vertical;
}

.form input:focus, .form > textarea:focus {
    outline: none;
}

.form button {
    margin-top: 25px;
    border-radius: 25px;
    width: 150px;
    align-self: center;
    padding: 5px 0px;
}

.light-button {
    background: #f3e6f3;
    color: #3a2069;
}

.dark-button {
    background: #9d4edd;
    color: white;
}

.form button:active {
    animation: clickButton 0.3s ease-in-out alternate;
}

.container-checkmark {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 175px;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 50px;
    font-weight: bold;
}

.container-checkmark > img {
    width: 100px;
}

.container-checkmark > p {
    color: white;
}

.light-form {
    background: #f3e6f3;
    border: solid 3px #9d4edd;
    color: #3A2069;
}

.dark-form {
    background: #3A2069;
    border: solid 1px #f3e6f3;
    color: #f3e6f3;
}

.light-form:focus {
    border: solid 3px #3A2069;
}
.dark-form:focus {
    border: solid 3px #f3e6f3;
}

@keyframes clickButton {
    0% {
        color: #f3e6f3;
        transform: scale(1);
    }
    100% {
        color: #a19c9c;
        transform: scale(0.9);
    }
}

/* Part tablet */
@media (min-width: 500px)  {
    .container-input {
        width: 48%;
    }

    .container-checkmark {
        margin-bottom: 100px;
    }

    .container-checkmark > img {
        width: initial;
    }

    .container-checkmark > p {
        width: 465px;
    }
}