nav {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    padding-top: 15px;
    z-index: 100;
    left: 0;
}

ul {
    display: flex;
    justify-content: space-around;
}

.ul_text {
    display: none;
}

.ul_text > li > a {
    text-decoration: underline;
    text-underline-offset: 8px;
    font-size: 17px;
}

.grey-background {
    background: #515151;
}

.white-background {
    background: #ffffff;
}

a:active > img {
    filter: brightness(0.5);
}

/* Part laptop */
@media (min-width: 800px) {
    nav {
        position: initial;
        background: none !important;
        padding: 0;
    }

    .ul_icon {
        display: none;
    }

    .ul_text {
        display: flex;
        justify-content: space-around;
        height: 100%;
        margin-top: 7px;
    }

    .ul_text > li > a {
        text-decoration: underline;
        text-underline-offset: 8px;
    }
}