.container-design_projects {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
}

.dark-design_projects {
    filter: brightness(0.5);
}

.waveSVG {
    margin-bottom: -8px;
}

.background-projects {
    background: rgb(255, 255, 255);
    height: 510px;
}

.container_projects {
    margin-top: 30px;
}

.sub-container_projects {
    margin-top: 25%;
    display: flex;
    flex-direction: column;
}

.sub-container_projects h2 {
    margin-bottom: 40px
}

.slick-slide {
    display: flex !important;
    justify-content: center;
}

.slick-prev, .slick-next {
    display: none !important;
}

@media (min-width: 500px)  {
    .container-slider {
        padding: 0 53px;
    }

    .slick-prev, .slick-next {
        display: block !important;;
    }
}

.slick-dots li button:before {
    font-size: 22px !important;
}

.slick-dots li.slick-active button:before {
    color: #9d4edd !important;
}

.card {
    height: 350px;
    border-radius: 25px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    padding: 25px 10px 0
}


@media (min-width: 350px)  {
    .card {
        padding: 25px 20px 0;
    }
}

@media (min-width: 700px)  {
    .slick-slide {
        display: block !important;
    }

    .card {
        width: auto;
        margin: 15px;
        max-width: 265px;
        min-width: 217px;
    }
}

@media (min-width: 800px)  {
    .slick-slide {
        display: flex !important;
    }
}

.light-card {
    background: #9d4edd;
    box-shadow: 2px 2px 4px 2px #9d4edd;
}

.dark-card {
    background: #3a2069;
    box-shadow: 2px 2px 4px 2px #0b002f;
}

.turned-card {
    animation: turnCard 0.5s ease-in-out both;
}

.init-card  {
    animation: initCard 0.5s ease-in-out both;
}

.hide-front-card {
    animation: hideFrontCard 0s ease-in-out 0.25s both;
}

.init-front-card {
    animation: initFrontCard 0s ease-in-out 0.25s both;
}

.back-card {
    opacity: 0;
    visibility: hidden;
    width: 80%;
    height: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
}

.display-back-card {
    animation: displayBackCard 0s ease-in-out 0.25s both;
}

.init-back-card {
    animation: initBackCard 0s ease-in-out 0.25s both;
}

.container-img-project {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 140px;
    border-radius: 12px;
}

.light-container-img {
    box-shadow: inset 0px 0px 50px #0b002f;
}
.dark-container-img {
    box-shadow: inset 0px 0px 100px #cdb1ff;
}

.container-img-project > img {
    width: 200px;
}

.container-details-card {
    text-align: center;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-stack {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

img[alt='sqlite'] {
    width: 60px;
    height: 30px;
}

.container-links {
    text-align: initial;
    display: flex;
    justify-content: space-around;
}

a img.icon-card, button img.icon-card {
    filter: brightness(9.5);
}

.container-links > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-site {
    border-style: none solid none solid; 
    border-width:  2.5px; 
    border-color: white;
    padding: 0 25px;
}

.turn-button {
    margin: 0;
    display: flex;
}

.circularArrow {
    transform: rotateY(190deg);
}

.text-card {
    color: white;
    margin-top: 20px;
    height: 275px;
    overflow: hidden;
}

/* To back of the card */
@keyframes turnCard {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes hideFrontCard {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes displayBackCard {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}


/* To front of the card */
@keyframes initCard {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes initFrontCard {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible; 
    }
}

@keyframes initBackCard {
    0% {
        opacity: 1;
        visibility: visible; 
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

/* Part tablet */
@media (min-width: 500px)  {
    .sub-container_projects {
        margin-top: 17vw;
    }

    .slick-dots li button:before {
        margin-top: 15px;
    }
}

/* Part laptop */
@media (min-width: 800px)  {
    .container_projects {
        margin-top: initial;
    }
}

@media (min-width: 1600px) {
    #AboutMe {
        margin-top: 10vw !important;
    }
}