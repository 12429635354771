button {
    width: 40px;
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.light-button-header {
    box-shadow: -1.5px 1.5px 0 #ffffff;
    color: #ffffff;
    border: solid 2px #ffffff;
    background:  #515151;
}

.dark-button-header {
    box-shadow: -1.5px 1.5px 0 #515151;
    color: #515151;
    border: solid 2px #515151;
    background: #ffffff;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    margin: 0;
}

#button-languages ul {
    border-top: solid 2px;
}

/* Part laptop */
@media (min-width: 800px)  {
    .light-button-header {
        background: #9d4edd;
    }

    .dark-button-header {
        background: #10002b;
        color: white;
        box-shadow: -1.5px 1.5px 0 #ffffff;
        border: solid 2px #ffffff;
    }
}