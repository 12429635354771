#AboutMe {
    margin-top: 65px;
}

#AboutMe p {
    color: white;
    font-size: x-large;
}

@media (min-width: 500px)  {
    #AboutMe {
        margin-top: 85px;
    }
}

@media (min-width: 800px)  {
    #AboutMe {
        margin-top: 100px;
    }
}